<template>
  <div v-if="$store.state.me.role !== 'partner'" class="lsg-cabinet">
    <div class="main__header">
      <div class="main__header__item left"></div>
      <div class="main__header__item">
        <h1>{{ title }}</h1>
      </div>
      <div class="main__header__item right"></div>
    </div>
    <div class="main__content">
      <form class="lk" autocomplete="off" @submit.prevent="saveMemo">
        <div class="lk__inputs">
          <div class="lk__field">
            <label>Тема:</label>
            <v-select
              appendToBody
              :value="fields.theme ? { label: fields.theme.name, value: fields.theme.id }: null"
              :options="themes.map(item => ({ value: item.id, label: item.name, themes: item.themes }))"
              placeholder=""
              @input="onThemeChange"
            >
              <template slot="no-options">Нет результатов</template>
              <template #open-indicator="{ attributes }">
                <div v-bind="attributes">
                  <DownIcon/>
                </div>
              </template>
            </v-select>
          </div>
          <div v-if="!fields.contract" class="lk__field">
            <label>Проект:</label>
            <v-select
              appendToBody
              :value="fields.project ? { label: fields.project.name, value: fields.project.id }: null"
              :options="projects.map(item => ({ value: item.id, label: item.name }))"
              placeholder=""
              @input="onProjectChange"
            >
              <template slot="no-options">Нет результатов</template>
              <template #open-indicator="{ attributes }">
                <div v-bind="attributes">
                  <DownIcon/>
                </div>
              </template>
            </v-select>
          </div>
          <div v-if="!fields.project" class="lk__field">
            <label>Договор:</label>
            <v-select
              appendToBody
              :value="fields.contract ? { label: fields.contract.name, value: fields.contract.id } : null"
              :options="contracts.map(item => ({ value: item.id, label: item.name }))"
              placeholder=""
              @input="onContractChange"
            >
              <template slot="no-options">Нет результатов</template>
              <template #open-indicator="{ attributes }">
                <div v-bind="attributes">
                  <DownIcon/>
                </div>
              </template>
            </v-select>
          </div>
          <div v-if="fields.theme && (fields.theme.id === 'feb9afde-11bd-11e9-a962-bcee7be31495' || fields.theme.id === '17b1c795-239b-11e9-bb4f-bcee7be31495')" class="lk__field">
            <label>Поставщик:</label>
            <input type="text" placeholder="Введите ИНН поставщика" v-model="fields.provider">
          </div>
          <div v-if="fields.project && fields.project.id && fields.theme && fields.theme.id" class="lk__field">
            <label>Имущество:</label>
            <div v-if="properties.length > 0" class="properties">
              <div class="messages apollo" v-if="isLoadingProperties">
                <div class="messages__status">Загрузка...</div>
              </div>
              <div class="messages apollo" v-else-if="isErrorProperties">
                <div class="messages__status error">Ошибка загрузки данных. Обратитесь в тех. поддержку</div>
              </div>
              <div v-for="property in properties" :key="property.id" class="properties__item">
                <input
                  style="margin-right: 20px"
                  type="checkbox"
                  :name="property.id"
                  :id="property.id"
                  :checked="property.checked"
                  @change="propertyChange(property)"
                  :value="property">
                <label :for="property.id">{{ property.name }}</label>
              </div>
            </div>
            <div v-else class="properties">Нет имущества</div>
          </div>
          <div v-if="fields.theme && fields.theme.id && fields.theme.id === 'd3c18e98-1730-11e9-a962-bcee7be31495'" class="lk__field">
            <label>Согласованты:</label>
            <v-select
              appendToBody
              :multiple="true"
              :value="fields.executors"
              :options="$store.state.allUsers.map(item => ({ value: item.id, label: item.name, user_id: item.user_id })) || []"
              placeholder=""
              @input="onUserChange"
            >
              <template slot="no-options">Нет результатов</template>
              <template #open-indicator="{ attributes }">
                <div v-bind="attributes">
                  <DownIcon/>
                </div>
              </template>
            </v-select>
          </div>
          <div v-if="fields.theme && fields.theme.id && (fields.theme.themes[0].param_represent !== '' || fields.theme.id === '968c0f67-6291-11e9-bb4f-bcee7be31495')" class="lk__field">
            <label>Параметры:</label>
            <div class="params">
              <div
                class="params__item"
                v-for="(param, index) in fields.theme.themes"
                :key="param.param_represent"
              >
                <label :for="param.param_represent">{{ param.param_represent }}</label>
                <input v-if="!isNaN(fields.theme.themes[index].param_value)"
                  style="max-width: 100px"
                  type="number"
                  step="0.01"
                  :id="param.param_represent"
                  v-model="fields.theme.themes[index].param_value"
                >
                <v-select v-else-if="['true', 'false'].includes(fields.theme.themes[index].param_value)"
                  v-model="fields.theme.themes[index].param_value"
                  label="label"
                  :reduce="item => item.value"
                  :options="[
                    { label: 'Да', value: 'true' },
                    { label: 'Нет', value: 'false' }
                  ]"
                  default="false"
                  :width="'100px'"
                  :style="{ 'max-width': '100px', width: '100px' }"
                  :clearable="false"
                />
                <CompanyInput
                  v-else-if="fields.theme.themes[index].param_represent === 'Контрагент'"
                  columnName="name"
                  :placeholder="companyData ? companyData.name : ''"
                  :currentValue="companyData ? companyData.name : ''"
                  filterName="q"
                  @change="onCompanyChange($event, index)"
                  @changeInput="onChangeInput($event, index)"
                />
                <input v-else type="text" v-model="fields.theme.themes[index].param_value">
                <!-- 00000000-0000-0000-0000-000000000000 -->
              </div>
            </div>
          </div>
          <div class="lk__field">
            <label>Текст служебной записки:</label>
            <textarea v-model="fields.comment"></textarea>
          </div>
          <div style="display: flex; justify-content: space-between; align-items: center; width: 100%">
            <div style="margin: 0 10px 0 0; width: 80px;">
              <vue-dropzone
                :id="'dropzoneMemo'"
                ref='dropzoneMemo'
                @vdropzone-files-added="filesPicked($event)"
                @vdropzone-upload-progress="fileProgress"
                @vdropzone-error="fileError"
                @vdropzone-canceled="fileCancel"
                @vdropzone-queue-complete="queueComplete"
                @vdropzone-removed-file="fileRemove"
                :includeStyling="false"
                useCustomSlot
                :options="dropzoneOptions">
                <div style="padding: 10px 5px 10px 17px; width: 60px; height: 40px" class="lk__dz">
                  <i>
                    <PaperClip/>
                  </i>
                </div>
              </vue-dropzone>
            </div>
            <div>
              <button v-if="!clicked" type="submit" class="btn">НАПРАВИТЬ НА РАССМОТРЕНИЕ</button>
              <button v-else disabled class="btn btn--gray">НАПРАВИТЬ НА РАССМОТРЕНИЕ</button>
            </div>
          </div>
          <div id="dz-previews" class="dz-previews"></div>
        </div>
        <div class="lk__back">
          <CompanyBack/>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import CompanyBack from '@/components/svg/CompanyBack'
import DownIcon from '@/components/svg/DownIcon'
import CREATE_MEMO from '@/graphql/mutations/CreateMemo.gql'
import GraphQLHelper from '@/helpers/GraphQLHelper'
import ALL_THEMES from '@/graphql/queries/AllMemoThemes.gql'
import ALL_PROJECTS from '@/graphql/queries/AllProjectLeads.gql'
import ALL_CONTRACTS from '@/graphql/queries/ResponsibleContracts.gql'
import PROPERTIES from '@/graphql/queries/ProjectProperties.gql'
import vue2Dropzone from 'vue2-dropzone'
import PaperClip from '@/components/svg/PaperClip'
import axios from 'axios'
import CompanyInput from '@/components/ui/GraphInputs/CompanyInput'

const getTemplate = () => `
<div class="dz-preview dz-file-preview">
  <div class="dz-details">
    <div class="dz-size"><span data-dz-size></span></div>
    <div class="dz-filename"><span data-dz-name></span></div>
  </div>
  <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
  <span class="dz-remove" data-dz-remove><a>Удалить</a></span>
</div>
`

const _graphQlHelper = new GraphQLHelper()

export default {
  name: 'AddMemo',
  components: {
    CompanyInput,
    DownIcon,
    CompanyBack,
    PaperClip,
    vueDropzone: vue2Dropzone
  },
  data () {
    return {
      title: 'Создать служебку',
      disabled: true,
      themes: [],
      projects: [],
      contracts: [],
      properties: [],
      isLoadingProperties: true,
      isErrorProperties: false,
      companyData: null,
      fields: {
        theme: null,
        project: null,
        contract: null,
        provider: '',
        properties: [],
        executors: [],
        comment: ''
      },
      memo: null,
      dropzoneOptions: {
        previewTemplate: getTemplate(),
        url: 'https://httpbin.org/post',
        parallelUploads: 1,
        // maxFiles: 4,
        // maxFilesize: 4, // 2Mb
        acceptedFiles: 'application/pdf',
        previewsContainer: '#dz-previews'
        // chunking: true
      },
      newFiles: null,
      clicked: false
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.$store.state.loadedPages.push({ title: this.$route.meta.title, route: this.$route.name })
    })
  },
  beforeUpdate () {
    this.transformMemo()
  },
  created () {
    this.getMemoThemes()
    this.getProjects()
    this.getContracts()
  },
  methods: {
    onCompanyChange (item, index) {
      this.companyData = {
        inn: item.inn,
        name: item.name
      }
      this.$forceUpdate()
    },
    onChangeInput ({ columnName, value }, index) {
      this.companyData[columnName] = value
    },
    queueComplete () {
      if (this.newFiles[0].files.length > 0) {
        this.newFiles[0].files.forEach(elem => {
          if (elem.file.status !== 'success') {
            this.$notify({
              group: 'lsg-notify',
              text: `Ошибка при загрузке файла ${elem.file.name}`
            })
          } else {
            this.$notify({
              group: 'lsg-notify',
              text: `Файл ${elem.file.name} готов к отправке`
            })
          }
        })
      }
    },
    fileRemove (file, error, xhr) {
      const updateFiles = []
      this.newFiles[0].files.forEach(elem => {
        if (elem.file.upload.uuid !== file.upload.uuid) {
          updateFiles.push(elem)
        } else {
          this.$notify({
            group: 'lsg-notify',
            text: `Файл ${elem.file.name} удалён`
          })
        }
      })
      this.newFiles[0].files = updateFiles
    },
    fileCancel (file) {
      this.$refs.dropzoneMemo.removeAllFiles()
      this.$notify({
        group: 'lsg-notify',
        text: `Отмена загрузки файла ${file.name}`
      })
    },
    fileProgress (file, progress, bytesSent) {
      file.previewElement.style.setProperty('--progress', progress + '%')
    },
    fileError (file, message, xhr) {
      file.previewElement.style.setProperty('background-color', '#FF0000')
      this.$notify({
        group: 'lsg-notify',
        text: 'Файл не может быть загружен'
      })
    },
    propertyChange (arg) {
      this.fields.properties = this.fields.properties.map(elem => {
        if (elem.id === arg.id) elem.checked = !arg.checked
        return elem
      })
    },
    getMemoThemes () {
      this.$apollo.mutate({
        mutation: ALL_THEMES,
        variables: {},
        update: (store, { data }) => {
          if (data) {
            const themes = []
            this.topThemes = data.allMemoThemes
              .filter(elem => elem.name_themes !== 'Неиспользовать') // && elem.name_themes !== 'Свободная форма'
              .forEach(elem => {
                if (
                  elem.id_themes === 'feb9afde-11bd-11e9-a962-bcee7be31495' ||
                  elem.id_themes === '17b1c795-239b-11e9-bb4f-bcee7be31495' ||
                  elem.id_themes === '8b1b1557-1256-11e9-a962-bcee7be31495' ||
                  elem.id_themes === 'd3c18e98-1730-11e9-a962-bcee7be31495' ||
                  elem.id_themes === '8988f8f3-2f53-11e9-bb4f-bcee7be31495' ||
                  elem.id_themes === 'f9b82e55-125c-11e9-a962-bcee7be31495'
                ) {
                  themes.unshift(elem)
                } else {
                  themes.push(elem)
                }
              })

            this.themes = themes.map(elem => (
              {
                id: elem.id_themes,
                name: elem.name_themes,
                themes: elem.themes
              }
            ))
          }
        }
      }).catch(error => {
        _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
          this.$notify({
            group: 'lsg-notify',
            text: item
          })
        })
      })
    },
    getContracts () {
      this.$apollo.mutate({
        mutation: ALL_CONTRACTS,
        variables: {},
        update: (store, { data }) => {
          if (data) {
            this.contracts = data.responsibleContracts.map(elem => (
              {
                id: elem.contract_id,
                name: elem.name
              }
            ))
          }
        }
      }).catch(error => {
        _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
          this.$notify({
            group: 'lsg-notify',
            text: item
          })
        })
      })
    },
    getProjects () {
      this.$apollo.mutate({
        mutation: ALL_PROJECTS,
        variables: {},
        update: (store, { data }) => {
          if (data) {
            this.projects = data.projectLeads
              .filter(elem => elem.project_id && elem.project_id !== '')
              .sort((elem, nextElem) => {
                return new Date(elem.createdAt).getTime() < new Date(nextElem.createdAt).getTime()
              })
              .map(elem => (
                {
                  id: elem.project_id,
                  name: `${elem.name || (elem.company && elem.company.name ? elem.company.name : '')} (${elem.company ? elem.company.inn : 'без ИНН'}) от ${new Date(elem.createdAt).toLocaleDateString()}`
                }
              ))
          }
        }
      }).catch(error => {
        _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
          this.$notify({
            group: 'lsg-notify',
            text: item
          })
        })
      })
    },
    getProperties (id) {
      this.$apollo.mutate({
        mutation: PROPERTIES,
        variables: {
          id
        },
        update: (store, { data }) => {
          if (data) {
            this.properties = data.projectProperties
            this.fields.properties = data.projectProperties
            this.isLoadingProperties = false
          }
        }
      }).catch(error => {
        _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
          this.$notify({
            group: 'lsg-notify',
            text: item
          })
          this.isErrorProperties = true
          this.isLoadingProperties = false
        })
      })
    },
    transformMemo () {
      const memo = {
        theme_id: '',
        theme_params: [],
        contract_id: '',
        project_id: '',
        properties: [],
        provider: '', // ИНН
        executor: '',
        comment: ''
      }
      if (this.fields.theme) {
        memo.theme_id = this.fields.theme.id
        memo.theme_params = this.fields.theme.themes
        memo.comment = this.fields.comment
        memo.contract_id = this.fields.contract ? this.fields.contract.id : ''
        memo.project_id = this.fields.project ? this.fields.project.id : ''

        memo.properties = (this.fields.theme.id && this.fields.theme.themes[0].param_represent !== '') && this.fields.properties && this.fields.project && this.fields.project.id ? this.fields.properties : []
        memo.provider = (this.fields.theme.id === 'feb9afde-11bd-11e9-a962-bcee7be31495' || this.fields.theme.id === '17b1c795-239b-11e9-bb4f-bcee7be31495') && this.fields.provider ? this.fields.provider : ''
        memo.executor = this.fields.theme.id === 'd3c18e98-1730-11e9-a962-bcee7be31495' && this.fields.executors.length > 0 ? this.fields.executors.map(elem => elem.label).join(';') : ''
      }

      this.memo = memo
    },
    saveMemo () {
      if (this.memo && this.memo.theme_id && this.memo.comment) {
        if (this.memo.theme_id === 'd3c18e98-1730-11e9-a962-bcee7be31495') {
          if (this.memo.executor.length === 0) {
            this.$notify({
              group: 'lsg-notify',
              text: `Не все поля заполнены`
            })
            throw new Error('Не все поля заполнены (согласованты)')
          }
        } else if (['feb9afde-11bd-11e9-a962-bcee7be31495', '17b1c795-239b-11e9-bb4f-bcee7be31495'].includes(this.memo.theme_id)) {
          if (![10, 12].includes(this.memo.provider.length)) {
            this.$notify({
              group: 'lsg-notify',
              text: `Поле "Поставщик" не заполнен, либо заполнен некорректно (ИНН 10 или 12 символов)`
            })
            throw new Error('Поле "Поставщик" не заполнен, либо заполнен некорректно (ИНН 10 или 12 символов)')
            // eslint-disable-next-line no-extra-boolean-cast
          } else if (!Number(this.memo.provider)) {
            this.$notify({
              group: 'lsg-notify',
              text: `Поле "Поставщик" не заполнен, либо заполнен некорректно (ИНН состоит исключительно из цифр)`
            })
            throw new Error('Поле "Поставщик" не заполнен, либо заполнен некорректно (ИНН состоит исключительно из цифр)')
          }
        } else if (this.memo.theme_id === 'b65f10f7-504b-11ed-80c9-00259047d1e4') {
          this.memo.theme_params[0].param_value = this.companyData ? this.companyData.inn : ''
        }
        this.clicked = true
        this.$apollo.mutate({
          mutation: CREATE_MEMO,
          variables: {
            input: this.memo
          },
          update: (store, { data }) => {
            if (data) {
              this.$notify({
                group: 'lsg-notify',
                text: `Служебная записка успешно создана`
              })
              if (this.newFiles && this.newFiles[0].files.length > 0) {
                this.saveFiles(data.createMemo.id)
              } else {
                this.clicked = false
                this.$router.push({ name: 'memos' })
              }
            }
          }
        }).catch(error => {
          _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
            this.clicked = false
            this.$notify({
              group: 'lsg-notify',
              text: 'Ошибка при создании служебки'
            })
          })
          this.clicked = false
        })
      } else {
        this.clicked = false
        this.$notify({
          group: 'lsg-notify',
          text: `Не все поля заполнены`
        })
      }
    },
    onThemeChange (e) {
      if (e) {
        this.fields.theme = {
          name: e.label,
          id: e.value,
          themes: e.themes
        }
      } else {
        this.fields.theme = null
      }
    },
    onProjectChange (e) {
      if (e) {
        this.fields.project = {
          name: e.label,
          id: e.value
        }
        this.getProperties(e.value)
      } else {
        this.fields.project = null
      }
      this.fields.contract = null
    },
    onContractChange (e) {
      if (e) {
        this.fields.contract = {
          name: e.label,
          id: e.value
        }
      } else {
        this.fields.contract = null
      }
      this.fields.project = null
    },
    onUserChange (e) {
      this.fields.executors = e
    },
    filesPicked (e) {
      this.newFiles = [{ files: [] }]
      this.newFiles[0].files = Array.from(e).map(file => ({ file: file, info: null }))
    },
    saveFiles (id) {
      let formData = new FormData()
      let index = 0
      this.newFiles[0].files.forEach((item) => {
        formData.append(`file[${index}][file]`, item.file)
        formData.append(`file[${index}][type]`, '')
        formData.append(`file[${index}][view]`, '')
        formData.append(`file[${index}][owType]`, '')
        formData.append(`file[${index}][doId]`, id)
        formData.append(`file[${index}][doType]`, '')
        formData.append(`file[${index}][user]`, this.$store.state.me.name)
        index += 1
      })
      const BASE_URL = process.env.VUE_APP_HTTP
      const url = `${BASE_URL}/upload`
      axios.post(url,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      ).then(() => {
        this.$notify({
          group: 'lsg-notify',
          text: 'Файлы успешно отправлены'
        })
        this.clicked = false
        this.$router.push({ name: 'memos' })
      }).catch(() => {
        this.clicked = false
        this.$notify({
          group: 'lsg-notify',
          text: 'Ошибка при отправке файлов'
        })
      })
    }
  }
}
</script>

<style lang="stylus">
#dz-previews {
  display flex
  flex-direction column
  margin-top 25px
  width 100%

  .dz-preview {
    position relative
    display flex !important
    flex-direction row !important
    padding 5px
    border 1px dashed $c4E555A
    border-radius 10px
    max-width 100% !important
    justify-content space-between
    z-index 2

    &:not(:last-child) {
      margin-bottom 10px
    }

    &::after {
      content: ''
      absolute left top
      background-color $anothergreen
      width var(--progress)
      opacity 0.5
      height 100%
      border-radius 10px
      z-index 1
    }
  }

  .dz-details {
    display flex !important
    flex-wrap nowrap !important
    flex-direction row-reverse !important
    z-index 2
    max-width 70%
  }

  .dz-filename {
    margin-right 10px
  }

  .dz-remove {
    z-index 2
    cursor: pointer
  }

  .dz-progress {
    position absolute
    top 0
    left 0
    background-color $green
    width 100%
  }

  .dz-upload {
    background-color $green
  }
}
</style>

<style lang="stylus" scoped>
.params
  &__item
    &:not(:last-child)
      margin-bottom 10px
    display flex
    max-width 350px
    flex-direction row
    justify-content space-between
</style>
